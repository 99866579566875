import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Button from 'src/components/common/Button';

const NavbarWrapper = styled(Navbar)`
  padding: 1.5rem 0;
`;

interface INavigation {
  data: {
    label: string;
    url: string;
  }[];
}

const Navigation = ({ data }: INavigation): ReactElement => {
  return (
    <NavbarWrapper
      variant="light"
      collapseOnSelect
      fixed="top"
      sticky="top"
      expand="lg"
    >
      <Container>
        <Navbar.Brand>
          <img src={'/images/skr-logo.svg'} height="30" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="m-auto justify-content-center"
            activeKey="/"
          >
            {data.map((navItem, i) => (
              <Nav.Item key={i}>
                <Link to={navItem.url} className="nav-link">
                  {navItem.label}
                </Link>
              </Nav.Item>
            ))}
          </Nav>
          <Nav>
            <Button type="button" to="/product">
              Build Product
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </NavbarWrapper>
  );
};

export default Navigation;
