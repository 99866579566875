import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'src/components/common/Button';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: bold;
  line-height: 35px;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  a:hover {
    color: ${({ theme }) => theme.colors.link};
  }
`;

const ContentRow = styled(Row)`
  margin: 5rem 0;
`;

const NavigationCol = styled(Col)`
  padding-left: 1rem;
`;

const Title = styled.h5`
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 2em;
  background-color: rgba(188, 208, 229, 0.5);
`;

const SubscribeForm = styled(FormControl)`
  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
`;

const Footer = (): ReactElement => (
  <Container style={{ padding: 0 }}>
    <Line />
    <ContentRow noGutters>
      <Col xs="6" lg="4">
        <img src={'/images/skr-logo.svg'} height="30" />
      </Col>

      <NavigationCol xs="6" lg="2">
        <Title>Quick Links</Title>
        <List>
          <ListItem>
            <Link to="/about">About Us</Link>
          </ListItem>
          <ListItem>
            <Link to="/blog">Blog</Link>
          </ListItem>
          <ListItem>
            <Link to="/contact">Contact</Link>
          </ListItem>
        </List>
      </NavigationCol>

      <NavigationCol xs="6" lg="2">
        <Title>Legal Stuffs</Title>
        <List>
          <ListItem>
            <Link to="/">Financing</Link>
          </ListItem>
          <ListItem>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </ListItem>
          <ListItem>
            <Link to="/terms-of-service">Terms of Service</Link>
          </ListItem>
        </List>
      </NavigationCol>

      <Col xs="6" lg="4">
        <Title>Subscribe to our blog</Title>
        <Form>
          <SubscribeForm
            placeholder="Enter Your Email"
            aria-label="Email"
          />
          <Button type="submit">Sign Up Now</Button>
        </Form>
      </Col>
    </ContentRow>

    <section style={{ margin: '1rem 0', textAlign: 'center' }}>
      <Link to="/">Stackwalker</Link> &copy; 2020
    </section>
  </Container>
);

export default Footer;
