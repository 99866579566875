import React, { ReactNode, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'emotion-theming';
import { stackwalkerTheme } from 'src/theme/stackwalker';
import Navigation from 'src/components/common/Layout/components/Navigation';
import Footer from 'src/components/common/Layout/components/Footer';

import 'src/styles/bootstrap.scss';
/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
interface ILayout {
  children: ReactNode;
  bodyClass: string;
  isHome: boolean;
  data: {
    allGhostSettings: {
      edges: {
        node: {
          lang: string;
          codeinjection_styles: {};
          navigation: {
            label: string;
            url: string;
          }[];
        };
      }[];
    };
  };
}

const DefaultLayout = ({
  data,
  children,
  bodyClass,
}: ILayout): ReactElement => {
  const site = data.allGhostSettings.edges[0].node;

  return (
    <ThemeProvider theme={stackwalkerTheme}>
      <Helmet>
        <html lang={site.lang} />
        <link
          href="https://fonts.googleapis.com/css2?family=Gelasio&display=swap"
          rel="stylesheet"
        ></link>
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>

      <header>
        <Navigation data={site.navigation} />
      </header>

      <main>{children}</main>

      <footer>
        <Footer />
      </footer>
    </ThemeProvider>
  );
};

const DefaultLayoutSettingsQuery = (props): ReactElement => {
  return (
    <StaticQuery
      query={graphql`
        query GhostSettings {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={(data) => <DefaultLayout data={data} {...props} />}
    />
  );
};
export default DefaultLayoutSettingsQuery;
