import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import BootstrapButton from 'react-bootstrap/Button';

export const StyledButton = styled(BootstrapButton)`
  font-size: ${({ theme }) => theme.btn.fontSize};
  font-weight: bold;
  box-shadow: ${({ shadow }) =>
    shadow === 'true' ? '0 10px 50px 0 rgba(42, 0, 160, 0.23)' : ''};
  background-image: ${({ theme }) => theme.btn.background};
  border-radius: ${({ radius }) => radius};
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: ${({ paddingleft }) => paddingleft};
  padding-right: ${({ paddingright }) => paddingright};
  a {
    color: ${({ theme }) => theme.btn.color} !important;
    text-decoration: none;
  }
`;

const Button = ({
  radius = '8px',
  paddingLeft = '2.2rem',
  paddingRight = '2.2rem',
  shadow = 'true',
  to = '/',
  type = 'button',
  children,
}: {
  radius?: string;
  paddingLeft?: string;
  paddingRight?: string;
  shadow?: string;
  to?: string;
  type: 'button' | 'submit';
  children?: ReactNode;
}) => {
  return (
    <StyledButton
      variant="primary"
      type={type}
      radius={radius}
      paddingleft={paddingLeft}
      paddingright={paddingRight}
      shadow={shadow}
    >
      {type === 'button' ? <Link to={to}>{children}</Link> : children}
    </StyledButton>
  );
};

export default Button;
