const colors = {
  background: '#ffffff',
  backgroundGray: '#f7f7f7',
  text: {
    primary: '#353535',
    secondary: '#1f51b1',
    inverted: '#ffffff',
  },
  link: '#1f51b1',
  blue: '#1f51b1',
};

const fontSize = {
  xsmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  xlarge: '20px',
};

export const stackwalkerTheme = {
  background: colors.background,
  backgroundGray: colors.backgroundGray,
  hero: {
    backgroundColor: colors.background,
    title: {
      color: colors.text.secondary,
      fontSize: '44px',
    },
    paragraph: {
      color: colors.text.primary,
      fontSize: fontSize.large,
    },
  },
  sections: {
    headlines: {
      xsmall: {
        fontSize: fontSize.medium,
        fontWeight: 'bold',
        color: colors.text.primary,
      },
      small: {
        fontSize: '24px',
        color: colors.text.primary,
      },
      medium: {
        fontSize: '31px',
        fontWeight: 'bold',
        color: colors.text.primary,
      },
      large: {
        fontSize: '40px',
        fontWeight: 'bold',
        color: colors.text.primary,
      },
    },
  },
  btn: {
    color: colors.text.inverted,
    fontSize: fontSize.medium,
    background: 'linear-gradient(114deg, #1e51b1 45%, #0d2879 92%)',
  },
  fontSize: fontSize,
  colors: colors,
};
